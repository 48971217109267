<template>
  <div>
    <c-search-box @enter="getDetail">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            :isArray="true"
            codeGroupCd="HEA_HEALTH_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="healthTypes"
            label="건강구분"
            maxValues="7"
            v-model="searchParam.healthTypes"
            @datachange="getDetail"
          ></c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-multi-select
            :editable="editable"
            :isArray="true"
            codeGroupCd="HEA_SUITABLE_FLAG_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="suitableFlags"
            label="업무수행적합여부"
            maxValues="3"
            v-model="searchParam.suitableFlags"
            @datachange="getDetail"
            ></c-multi-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="검진결과 목록"
      tableId="table"
      :columns="grid.columns"
      gridHeightAuto
      :data="grid.data"
      :filtering="true"
      :columnSetting="false"
      :usePaging="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :expandAll="true"
      checkDisableColumn="suspectDisable"
      selection="multiple"
      noDataLabel="검진결과를 추가하세요."
      rowKey="heaCheckupResultOpinionId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- <c-btn label="유소견자 처리" icon="save" @btnClicked="suspectPop" />  -->
          <c-btn label="검진결과 업로드" icon="upload"  @btnClicked="openExcelUploader"/>
          <c-btn v-if="editable && grid.data.length>0"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="saveData"
            mappingType="POST"
            label="유소견자 지정"
            icon="add"
            @beforeAction="saveRow"
            @btnCallback="saveCallback" />
          <c-btn v-if="editable&&grid.data.length>0" label="전체 삭제" icon="delete_forever" @btnClicked="removeAll" />
        </q-btn-group>
      </template>
      <template slot="table-chip">
        <font color="#C10015">
          ※ {{ $language('추가로 엑셀업로드 시 기존데이터에 결과가 추가됩니다(중복 허용). 다시 업로드 하고 싶으면 전체 삭제 후 진행하세요.') }}
        </font>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='customReg'">
          <font v-if="props.row.suspectId" color="#055dff">
            O
            <q-icon 
              size="sm"
              name="delete_forever"
              class="cursor-pointer"
              color="negative"
              style="font-weight:700"
              @click="deleteSuspect(props.row)" >
              <q-tooltip>
                유소견자 제거
              </q-tooltip>
            </q-icon>
          </font>
          <font v-else color="#C10015">
            X
          </font>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions" :isConfirm="true"></c-dialog>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'check-up-result',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
      }),
    },
    checkUp: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        checkupStartDate: '',
        checkupEndDate: '',
        regUserId: '',
        chgUserId: '',
        uploadFlag: 'N',

        // prototype
        hospitalId: '',
        hospitalLocation: '',
        hospitalName: '',
        typeFlag: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: true,
      grid: {
        merge: [
          { index: 0, colName: 'upDeptCd' },
          { index: 1, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: '소속',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'empNo',
            field: 'empNo',
            label: '사번',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link'
          },
          {
            name: 'birthDate',
            field: 'birthDate',
            label: '생년월일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'checkupDate',
            field: 'checkupDate',
            label: '검진일',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'healthType',
            field: 'healthType',
            label: '건강구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'suitableFlag',
            field: 'suitableFlag',
            label: '업무수행적합여부',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'hazardName',
            field: 'hazardName',
            label: '유해인자',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'checkupOpinion',
            field: 'checkupOpinion',
            label: '검진소견',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'opinionDetailResult',
            field: 'opinionDetailResult',
            label: '소견 세부결과',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'afterManage',
            field: 'afterManage',
            label: '사후관리소견',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'customReg',
            field: 'customReg',
            label: '유소견자<br>지정여부',
            align: 'center',
            type: 'custom',
            style: 'width:90px',
            sortable: false,
          },
        ],
        data: [],
        height: '800px',
      },
      isSave: false,
      mappingType: 'POST',
      listUrl: '',
      saveUrl: '',
      searchParam: {
        heaCheckupPlanId: '',
        healthTypes: [],
        suitableFlags: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
        isUpload: false,
        saveUploadUrl: '',
      },
      saveData: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.hea.checkup.opinion.list.url;
      this.deleteUrl = transactionConfig.hea.checkup.results.delete.url;
      this.saveUploadUrl = transactionConfig.hea.examine.resultUpload.url;
      this.saveUrl = transactionConfig.hea.checkup.suspect.user.save.url;
      this.deleteReleaseUrl = transactionConfig.hea.checkup.suspect.user.delete.url;
      this.getDetail();
      this.deleteTemps();
    },
    getDetail() {
      if (this.checkUp.heaCheckupPlanId) {
        this.searchParam.heaCheckupPlanId = this.checkUp.heaCheckupPlanId
        this.$http.url = this.listUrl
        this.$http.type = 'GET';
        this.$http.param = this.searchParam;
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      } 
    },
    linkClick(result) {
      this.popupOptions.target = () => import(`${"./checkUpResultDetail.vue"}`);
      this.popupOptions.title = result.userName + '의 건강검진 결과 상세';
      this.popupOptions.width = '70%';
      this.popupOptions.isFull = true;
      this.popupOptions.param = {
        heaCheckupPlanId: result ? result.heaCheckupPlanId : '',
        userId: result ? result.userId : '',
        checkupDate: result ? result.checkupDate : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    openExcelUploader() {
      this.popupOptions.title = '건강검진 결과 업로드';
      this.popupOptions.param = {
        checkUp: this.checkUp,
        heaCheckupPlanId: this.popupParam.heaCheckupPlanId,
        hospitalId: this.checkUp.hospitalId,
        hospitalName: this.checkUp.hospitalName,
        plantCd: this.checkUp.plantCd,
      }
      this.popupOptions.target = () => import(`${'./checkUpResultOpinionUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.data && _result.data[0].length > 0) {
        let saveData = {
          heaCheckupPlanId: this.checkUp.heaCheckupPlanId,
          heaCheckupResultId: uid(),
          regUserId: this.$store.getters.user.userId,
          chgUserId: this.$store.getters.user.userId,
        }
        this.$http.url = this.saveUploadUrl
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getDetail();
          this.deleteTemps();
        }, ()=>{
          // Handle error
          window.getApp.$emit('ALERT', {
            title: '오류',
            message: '데이터 저장 중 오류가 발생했습니다.',
            type: 'error',
          });
        });
      } else {
        this.deleteTemps();
      }

    },
    // 업로드용 Temp Data 일괄 삭제
    deleteTemps() {
      this.$http.url = transactionConfig.hea.examine.tempUploadDelete.url;
      this.$http.type = 'DELETE';
      this.$http.request();
    },
    removeAll() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까? ',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.checkUp.heaCheckupPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('update', uid())
            this.getDetail();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveRow() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '선택한 대상자를 유소견자로 등록하시겠습니까?',
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.saveData = [];
            this.$_.forEach(selectData, _item => {
              this.saveData.push({
                suspectId: uid(),
                heaCheckupResultOpinionId: _item.heaCheckupResultOpinionId,
                heaCheckupPlanId: _item.heaCheckupPlanId,
                userId: _item.userId,
                userName: _item.userName,
                healthType: _item.healthType,
                regUserId:  this.$store.getters.user.userId,
              })
            })
            this.isSave = !this.isSave;
            this.$refs['table'].selected = []
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    deleteSuspect(row) {
      this.$http.url = this.$format(this.deleteReleaseUrl, row.heaCheckupResultOpinionId);
      this.$http.type = 'DELETE';
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.$emit('update', uid())
        this.getDetail();
      })
    }
  },
};
</script>

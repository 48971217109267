var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getDetail } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    isArray: true,
                    codeGroupCd: "HEA_HEALTH_TYPE_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "healthTypes",
                    label: "건강구분",
                    maxValues: "7",
                  },
                  on: { datachange: _vm.getDetail },
                  model: {
                    value: _vm.searchParam.healthTypes,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "healthTypes", $$v)
                    },
                    expression: "searchParam.healthTypes",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-multi-select", {
                  attrs: {
                    editable: _vm.editable,
                    isArray: true,
                    codeGroupCd: "HEA_SUITABLE_FLAG_CD",
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "suitableFlags",
                    label: "업무수행적합여부",
                    maxValues: "3",
                  },
                  on: { datachange: _vm.getDetail },
                  model: {
                    value: _vm.searchParam.suitableFlags,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "suitableFlags", $$v)
                    },
                    expression: "searchParam.suitableFlags",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "검진결과 목록",
            tableId: "table",
            columns: _vm.grid.columns,
            gridHeightAuto: "",
            data: _vm.grid.data,
            filtering: true,
            columnSetting: false,
            usePaging: false,
            isExcelDown: false,
            isFullScreen: false,
            expandAll: true,
            checkDisableColumn: "suspectDisable",
            selection: "multiple",
            noDataLabel: "검진결과를 추가하세요.",
            rowKey: "heaCheckupResultOpinionId",
          },
          on: { linkClick: _vm.linkClick },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "customReg"
                    ? [
                        props.row.suspectId
                          ? _c(
                              "font",
                              { attrs: { color: "#055dff" } },
                              [
                                _vm._v(" O "),
                                _c(
                                  "q-icon",
                                  {
                                    staticClass: "cursor-pointer",
                                    staticStyle: { "font-weight": "700" },
                                    attrs: {
                                      size: "sm",
                                      name: "delete_forever",
                                      color: "negative",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteSuspect(props.row)
                                      },
                                    },
                                  },
                                  [
                                    _c("q-tooltip", [
                                      _vm._v(" 유소견자 제거 "),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c("font", { attrs: { color: "#C10015" } }, [
                              _vm._v(" X "),
                            ]),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _c("c-btn", {
                    attrs: { label: "검진결과 업로드", icon: "upload" },
                    on: { btnClicked: _vm.openExcelUploader },
                  }),
                  _vm.editable && _vm.grid.data.length > 0
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.saveData,
                          mappingType: "POST",
                          label: "유소견자 지정",
                          icon: "add",
                        },
                        on: {
                          beforeAction: _vm.saveRow,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _vm.editable && _vm.grid.data.length > 0
                    ? _c("c-btn", {
                        attrs: { label: "전체 삭제", icon: "delete_forever" },
                        on: { btnClicked: _vm.removeAll },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "template",
            { slot: "table-chip" },
            [
              _c("font", { attrs: { color: "#C10015" } }, [
                _vm._v(
                  " ※ " +
                    _vm._s(
                      _vm.$language(
                        "추가로 엑셀업로드 시 기존데이터에 결과가 추가됩니다(중복 허용). 다시 업로드 하고 싶으면 전체 삭제 후 진행하세요."
                      )
                    ) +
                    " "
                ),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions, isConfirm: true } }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }